import { TypedEvent } from "@faro-lotv/foundation";
import { Camera, OrthographicCamera, PerspectiveCamera, Quaternion, Vector3 } from "three";

const EPS = 0.0000001;
const SECS_AFTER_CAMERA_CHANGED = 0.3;

/**
 * A small class to encapsulate the functionality to check for changes in a given camera. Useful
 * to monitor control classes and in rendering pipelines that re.render a scene on-demand, i.e.
 * only when the camera changes.
 */
export class CameraMonitor {
	/** The camera parameters at which the last change has been detected */
	#lastCameraPos = new Vector3();
	#lastCameraRot = new Quaternion();
	#lastFOV = 1.0;
	#lastZoom = 1.0;

	/** Event emitted when the monitored camera switches from still to moving */
	cameraStartedMoving = new TypedEvent<void>();

	/** Event emitted when the monitored camera switches from moving to still */
	cameraStoppedMoving = new TypedEvent<void>();

	/** Whether the camera is still or moving */
	#cameraMoving = false;

	/** Duration from the last time the camera was moving, in seconds. */
	#secsSinceLastChange = 0;

	/**
	 * Checks whether the argument camera pose or projection changed or not with respect to the
	 * pose and projection of the last detected change.
	 *
	 * @param camera The camera to confront with the stored camera properties
	 * @returns true iff the camera position, rotation, or projection changed since the last detected change.
	 */
	cameraChanged(camera: Camera): boolean {
		let ret = false;
		if (
			this.#lastCameraPos.distanceToSquared(camera.position) > EPS ||
			// using small-angle approximation cos(x/2) = 1 - x^2 / 8
			8 * (1 - this.#lastCameraRot.dot(camera.quaternion)) > EPS
		) {
			this.#lastCameraPos.copy(camera.position);
			this.#lastCameraRot.copy(camera.quaternion);

			ret = true;
		}
		let fov = 0.0;
		let zoom = 0.0;
		if (camera instanceof PerspectiveCamera) {
			({ fov, zoom } = camera);
		} else if (camera instanceof OrthographicCamera) {
			fov = camera.top - camera.bottom;
			({ zoom } = camera);
		}
		if (Math.abs(this.#lastFOV - fov) > EPS) {
			this.#lastFOV = fov;
			ret = true;
		}
		if (Math.abs(this.#lastZoom - zoom) > EPS) {
			this.#lastZoom = zoom;
			ret = true;
		}
		return ret;
	}

	/**
	 * Checks whether to emit the 'cameraStartedMoving' or 'cameraStoppedMoving'
	 *
	 * @param camera The camera to check
	 * @param deltaTime Time elapsed since last controls update, in seconds
	 */
	checkCameraMovement(camera: Camera, deltaTime: number): void {
		const changed = this.cameraChanged(camera);

		if (changed) {
			this.#secsSinceLastChange = 0;
		}

		if (changed !== this.#cameraMoving) {
			if (changed) {
				this.#cameraMoving = changed;
				this.cameraStartedMoving.emit();
			} else {
				// When moving the camera with the mouse, it can happen that the
				// control updates are more frequent than the mouse events, therefore
				// we would emit a lot of camera stopped and camera started events.
				// To prevent that, we emit a camera stopped event only
				// SECS_AFTER_CAMERA_CHANGED seconds.
				this.#secsSinceLastChange += deltaTime;
				if (this.#secsSinceLastChange > SECS_AFTER_CAMERA_CHANGED) {
					this.#secsSinceLastChange = 0;
					this.#cameraMoving = changed;
					this.cameraStoppedMoving.emit();
				}
			}
		}
	}

	/** @returns whether the camera is moving or is still. */
	get cameraMoving(): boolean {
		return this.#cameraMoving;
	}
}
