import { GUID } from "@faro-lotv/ielement-types";
import { useMemo } from "react";
import { useCoreApiTokenProvider } from "../authentication";
import { ProjectApi } from "./project-api";

/**
 * @returns Instance of the ProjectApi client
 * @param projectApiUrl The URL to call the Project API with
 * @param coreApiUrl the URL to call the Core API with
 * @param projectId ID of the project to use the client with
 * @param clientId A string to identify this client in the format client/version
 */
export function useProjectApiClient(
  projectApiUrl: string,
  coreApiUrl: string,
  projectId: GUID,
  clientId: string,
): ProjectApi {
  const tokenProvider = useCoreApiTokenProvider({
    coreApiBaseUrl: coreApiUrl,
    projectId,
  });

  // Make sure the client is only created once, unless projectId or token provider change
  return useMemo(
    () =>
      new ProjectApi({
        projectApi: new URL(projectApiUrl),
        projectId,
        tokenProvider,
        clientId,
      }),
    [projectId, tokenProvider],
  );
}
