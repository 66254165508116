import { Frustum, Matrix4, Plane } from "three";

/**
 * A class representing the status of clipping planes for a point cloud.
 * This class is meant to be used internally by LodPointCloud, and not to
 * be exported outside the library.
 */
export class ClipPlanesStatus {
	/** Whether clipping planes are active */
	isClipping: boolean = false;
	/** Clipping box expressed as a frustum in local space of the point cloud. */
	localFrustum = new Frustum();
	/** Clipping box expressed as a frustum in world space. */
	worldFrustum = new Frustum();
	/** The inverse world matrix used at the previous update */
	#prevWorldInverse = new Matrix4();

	/**
	 * Updates the clipping status of the point cloud.
	 *
	 * @param planes The six clipping planes in world coordinates, or null
	 * @param worldInverse The inverse of the world matrix of the point cloud
	 */
	update(planes: Plane[] | null, worldInverse: Matrix4): void {
		if (planes?.length === 6) {
			this.isClipping = true;
			if (!arePlanesEqual(planes, this.worldFrustum.planes) || !this.#prevWorldInverse.equals(worldInverse)) {
				this.worldFrustum.set(planes[0], planes[1], planes[2], planes[3], planes[4], planes[5]);
				this.localFrustum.copy(this.worldFrustum);
				for (const p of this.localFrustum.planes) {
					p.applyMatrix4(worldInverse);
				}
				this.#prevWorldInverse.copy(worldInverse);
			}
		} else {
			this.isClipping = false;
		}
	}
}

function arePlanesEqual(a: Plane[], b: Plane[]): boolean {
	for (let i = 0; i < a.length; i++) {
		if (!a[i].equals(b[i])) {
			return false;
		}
	}
	return true;
}
