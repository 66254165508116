import { Box2, MirroredRepeatWrapping, NearestFilter, Texture, Vector2 } from "three";
import { ImageNodeFetch, ImageTree, ImageTreeNode } from "../Lod/ImageTree";
import { FloorPlanVisibleTilesStrategy } from "../Lod/VisibleTilesStrategy";
import { TextureLoader } from "../Utils/TextureLoader";
import { FloorPlanLevel } from "./FloorPlan";

const loader = new TextureLoader();

/**
 * Node fetch class for retrieving the texture of a tile
 */
export class FloorPlanNodeFetch implements ImageNodeFetch {
	#controller: AbortController | undefined;

	/**
	 * Constructor class that stores the node
	 *
	 * @param node The node itself.
	 */
	constructor(private node: ImageTreeNode<string>) {}

	/**
	 * Return the image belonging to this node.
	 *
	 * @returns An already resolved promise.
	 */
	async image(): Promise<Texture> {
		if (!this.node.image) {
			const { promise, abort } = loader.load(this.node.source);
			this.#controller = abort;
			const texture = await promise;
			texture.wrapS = MirroredRepeatWrapping;
			texture.wrapT = MirroredRepeatWrapping;
			texture.minFilter = NearestFilter;
			texture.magFilter = NearestFilter;
			texture.generateMipmaps = false;
			texture.needsUpdate = true;
			this.node.image = texture;
		}
		return this.node.image;
	}

	/**
	 * Abort the download of the texture for this node
	 */
	abort(): void {
		if (this.#controller) {
			this.#controller.abort();
		}
	}
}

/**
 * Create a key for the map when building the tree structure
 *
 * @param z The z-coordinate of the tile
 * @param x The x coordinate of the tile
 * @param y The y coordinate of the tile
 * @returns The key encoding the tile
 */
function createKey(z: number, x: number, y: number): string {
	return `${z.toString()}-${x.toString()}-${y.toString()}`;
}

/**
 * A subclass of ImageTree developed to parse Tiled floor plans data.
 */
export class FloorPlanTree extends ImageTree<string> {
	width: number;
	height: number;

	/**
	 * Constructs a new FloorPlane tree
	 *
	 * @param lod The data parsed describing the tree structure
	 */
	constructor(lod: FloorPlanLevel[]) {
		super();

		this.visibleTilesStrategy = new FloorPlanVisibleTilesStrategy();

		// A leaflet tree is always a 100x100 square, the real world sizes are defined at the object level
		this.width = 100;
		this.height = 100;

		const parentsMap = new Map<string, number>();

		// Recursive function to find a parent for a given tile
		function findParent(level: number, x: number, y: number): number | undefined {
			if (level === 0) {
				return;
			}
			const xParent = Math.floor(x / 2);
			const yParent = Math.floor(y / 2);
			const parentKey = createKey(level - 1, xParent, yParent);
			if (parentsMap.has(parentKey)) {
				return parentsMap.get(parentKey);
			}
			return findParent(level - 1, xParent, yParent);
		}

		for (const { level, dimX, dimY, sources } of lod) {
			for (const s of sources) {
				const { x, y, source } = s;
				const parent = findParent(level, x, y);

				if (parent === undefined && level > 0) {
					console.warn(`Cannot find parent for node ${x}, ${y}, ${level}.`);
				} else {
					this.appendChildren(parent, {
						source,
						rect: new Box2(new Vector2(x / dimX, y / dimY), new Vector2((x + 1) / dimX, (y + 1) / dimY)),
					});

					const nodeIdx = this.nodeCount;
					parentsMap.set(createKey(level, x, y), nodeIdx - 1);
				}
			}
		}
	}

	/**
	 * Get the image for a node
	 *
	 * @param nodeOrId The node or the node id to fetch the image
	 * @returns An object to wait for the image or cancel the fetch
	 */
	override getNodeImage(nodeOrId: number | ImageTreeNode<string>): Promise<ImageNodeFetch> {
		const node = typeof nodeOrId === "number" ? this.nodesList[nodeOrId] : nodeOrId;
		return Promise.resolve(new FloorPlanNodeFetch(node));
	}
}
