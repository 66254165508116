import { ParsingError } from "@faro-lotv/foundation";
import { Box3, Matrix4, Quaternion, Vector3 } from "three";
import { VisibleNodesStrategy } from "../Lod";
import { LodNodeFetch, LodTree, LodTreeNode, NodeState } from "../Lod/LodTree";
import { invertedRigid } from "../Utils/GeometricUtils";
import { WSCdnManager } from "./WSCdnManager";
import { WSInstance } from "./WSInstance";
import { WSVisibleNodesStrategy } from "./WSVisibleNodeStrategy";
import { WSTreeNodeDesc } from "./responses/WSTreeNodeDesc.gen";

const NODE_ID_SLICE_END = -2;

/**
 * A node inside the webshare KDTree
 */
export class WSKDTreeNode implements LodTreeNode {
	#boundingBox: Box3;
	#children = new Array<WSKDTreeNode>();
	#id: number;
	#pointDensity: number;
	#desc: WSTreeNodeDesc;
	#depth: number;
	#parentId?: number;

	/**
	 * Construct a new node of the tree
	 *
	 * @param id The node index in the original buffer
	 * @param pointDensity The distance in mm between two points at this level
	 * @param desc The webshare node description
	 * @param depth The depth of this node in the tree
	 * @param parentId The id of the parent node
	 */
	constructor(id: number, pointDensity: number, desc: WSTreeNodeDesc, depth: number, parentId?: number) {
		this.#id = id;
		this.#pointDensity = pointDensity;
		this.#desc = desc;
		this.#depth = depth;
		this.#parentId = parentId;
		this.#boundingBox = new Box3();
		this.#boundingBox.min.fromArray(desc.Min);
		this.#boundingBox.max.fromArray(desc.Max);
		this.pixelsPerPoint = 1;
	}

	/** @returns the node id */
	get id(): number {
		return this.#id;
	}

	/** @returns the node bounding box */
	get boundingBox(): Box3 {
		return this.#boundingBox;
	}

	/** @returns the node parent or undefined if this is the root node */
	get parentId(): number | undefined {
		return this.#parentId;
	}

	/** @returns the node children */
	get children(): WSKDTreeNode[] {
		return this.#children;
	}

	/** @returns the number of points in this node */
	get numPoints(): number {
		return this.#desc.Points;
	}

	/** @returns the distance between two points in mm in this node */
	get pointDensity(): number {
		return this.#pointDensity;
	}

	/** @inheritdoc */
	pixelsPerPoint: number;

	/** @returns this node depth in the tree */
	get depth(): number {
		return this.#depth;
	}

	/** @returns the unique id for this node as defined in webshare */
	get uuid(): string {
		return this.#desc.ID;
	}

	/** The node's state */
	public state = NodeState.NotInUse;
}

/**
 * A webshare KDTree
 */
export class WSKDTree implements LodTree<WSKDTreeNode> {
	#root: WSKDTreeNode;
	#worldMatrix = new Matrix4();
	#worldMatrixInverse = new Matrix4();
	#rootDesc: Required<WSTreeNodeDesc>;
	#nodes: WSKDTreeNode[];
	#cdn?: WSCdnManager;
	visibleNodesStrategy: VisibleNodesStrategy = new WSVisibleNodesStrategy();
	/**
	 * Construct a webshare kd tree
	 *
	 * @param ws The webshare instance to query nodes
	 * @param name the point cloud name
	 * @param uuid This entity uuid
	 * @param descs All the nodes descriptions
	 * @param ignorePose if true the world matrix of the tree will be ignored (useful for backward compatibility)
	 */
	constructor(
		private ws: WSInstance,
		private name: string,
		public uuid: string,
		descs: WSTreeNodeDesc[],
		ignorePose = false,
	) {
		if (ws.url.includes("webshare")) {
			this.#cdn = new WSCdnManager(ws, name);
		}
		const rootDesc = descs[0];
		if (!isRootDesc(rootDesc)) throw new ParsingError("PointCloudRootNode");
		this.#nodes = new Array<WSKDTreeNode>(descs.length - 1);
		// Map at each ID all its children
		const childrenMap = new Map<string, number[]>();
		for (const [idx, node] of descs.entries()) {
			const parentId = node.ID.slice(0, NODE_ID_SLICE_END);
			if (childrenMap.has(parentId)) {
				childrenMap.get(parentId)?.push(idx);
			} else {
				childrenMap.set(parentId, [idx]);
			}
		}

		// compute the point density in millimeters at the root node
		// from the Homogenization parameter that is the density in meter at the max depth
		// the density double at every level
		const rootPointDensity = (() => {
			const numLevels = rootDesc.Depth;
			let multiplier = 2;
			for (let i = 1; i < numLevels - 1; ++i) {
				multiplier *= 2;
			}
			return 1000 * multiplier * rootDesc.Homogenization;
		})();

		/**
		 * Create a node and recursively create all child nodes appending them
		 *
		 * @param desc Descriptor of the node we want to create
		 * @param depth The depth of this node
		 * @param parent The parent of this node
		 * @returns The created node
		 */
		const createNode = (desc: WSTreeNodeDesc, depth = 0, parent?: WSKDTreeNode): WSKDTreeNode => {
			const id = descs.indexOf(desc);
			// const Q = desc.Q === 1 ? 2 : desc.Q;
			// const pointDensity = parent ? parent.pointDensity / Q : rootPointDensity;
			const pointDensity = parent ? parent.pointDensity / 2 : rootPointDensity;
			// const node = Object.freeze(new WSKDTreeNode(id, pointDensity, desc, depth, parent?.id));
			const node = new WSKDTreeNode(id, pointDensity, desc, depth, parent?.id);
			for (const childrenId of childrenMap.get(desc.ID) ?? []) {
				node.children.push(createNode(descs[childrenId], depth + 1, node));
			}
			this.#nodes[id] = node;
			return node;
		};
		this.#rootDesc = rootDesc;
		this.#root = createNode(rootDesc);
		if (!ignorePose) {
			this.#worldMatrix.fromArray(rootDesc.TransformationGlobal);
			invertedRigid(this.#worldMatrix, this.#worldMatrixInverse);
		}
	}

	/** @returns the root node */
	get root(): WSKDTreeNode {
		return this.#root;
	}

	/** @returns the max depth of this tree */
	get maxDepth(): number {
		return this.#rootDesc.Depth;
	}

	/** @returns this tree position vector */
	get position(): Vector3 {
		return new Vector3().setFromMatrixPosition(this.#worldMatrix);
	}

	/** @returns this tree quaternion */
	get quaternion(): Quaternion {
		return new Quaternion().setFromRotationMatrix(this.#worldMatrix);
	}

	/** @returns The tree pose matrix. */
	get worldMatrix(): Matrix4 {
		return this.#worldMatrix;
	}

	/** @returns the inverse pose of the tree. */
	get worldMatrixInverse(): Matrix4 {
		return this.#worldMatrixInverse;
	}

	/** @returns the total number of nodes in this tree */
	get numNodes(): number {
		return this.#nodes.length;
	}

	/** @returns a list with all the nodes */
	get nodes(): WSKDTreeNode[] {
		return this.#nodes;
	}

	/**
	 * Get a node from an id
	 *
	 * @param id the id of the node we want
	 * @returns the node with the id passed
	 */
	getNode(id: number): WSKDTreeNode {
		return this.#nodes[id];
	}

	/** @returns The strict bounding box of the point cloud */
	get boundingBox(): Box3 {
		return this.#root.boundingBox;
	}

	/**
	 * Get the points for a node
	 *
	 * @param nodeOrId The node or the node id to fetch the points for
	 * @returns An object to wait for the points or cancel the fetch
	 */
	getNodePoints(nodeOrId: WSKDTreeNode | number): Promise<LodNodeFetch> {
		const node = typeof nodeOrId === "number" ? this.getNode(nodeOrId) : nodeOrId;
		return this.ws.getNode(this.name, this.uuid, node.uuid, this.#cdn);
	}

	/**
	 * Sets the global pose of the tree
	 *
	 * @param m The global pose
	 */
	setWorldMatrix(m: Matrix4): void {
		this.#worldMatrix = m;
		this.#worldMatrixInverse = invertedRigid(m, this.#worldMatrixInverse);
	}
}

function isRootDesc(desc: WSTreeNodeDesc): desc is Required<WSTreeNodeDesc> {
	return desc.Class === "PointCloudRootNode";
}
