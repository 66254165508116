import { TypedEvent } from "@faro-lotv/foundation";

/** Layout-independent key descriptor (see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code) */
export type KeyCode = string;
/** Layout-and-modifier-dependent key descriptor (see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key) */
export type Key = string;

/**
 * Provides commonly used keyboard-events and -queries for a dom-element target.
 * Use e.g. if a current key's state needs to be queried or a non-standard event like "keyChanged" is needed.
 */
export class KeyboardEvents {
	/** The HTML element to receive the keyboard events from. */
	#element: HTMLElement | undefined;

	/**
	 * A map of currently pressed `KeyboardEvent.code`'s (layout independent) to their initial keydown events.
	 * The initial key-down events are stored to create simulated key-up events with matching values in case the target element is blurred.
	 */
	#pressedCodes = new Map<KeyCode, KeyboardEvent>();

	/** The set of currently pressed `KeyboardEvent.key`'s (layout dependent). */
	#pressedKeys = new Set<Key>();

	/**
	 * Create a new keyboard event tracker.
	 *
	 * @param element The element to use for event listening.
	 * If this is not specified, `.attach` needs to be used before events are triggered.
	 */
	constructor(element?: HTMLElement) {
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onKeyUp = this.onKeyUp.bind(this);
		this.onBlur = this.onBlur.bind(this);

		if (element !== undefined) {
			this.attach(element);
		}
	}

	/**
	 * Determines if the given logical key is currently pressed.
	 *
	 * If you care about the physical position on the keyboard instead of the emitted character,
	 * use `.isCodePressed` instead.
	 *
	 * @param key - The key code to check.
	 * @returns `true` if the given logical key is currently pressed.
	 */
	isKeyPressed(key: Key): boolean {
		return this.#pressedKeys.has(key);
	}

	/**
	 * Determines if the key at the physical location determined by the key code is currently pressed.
	 *
	 * The actual key that will have to be pressed depends on the keyboard layout.
	 * The key code determines the position, based on the QWERTY layout.
	 * Use this function when the position of the button matters, e.g. for movement input.
	 * See https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code for more information.
	 *
	 * @param code - The key code to check.
	 * @returns `true` if the key at the position determined by the code is currently pressed.
	 */
	isCodePressed(code: KeyCode): boolean {
		return this.#pressedCodes.has(code);
	}

	/**
	 * Map the input for the given key codes to an axis input.
	 *
	 * Note that the actual keys that have to be pressed depend on the keyboard layout.
	 * See https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code for more information.
	 *
	 * @param positiveCode The key code for input towards the positive of the axis.
	 * @param negativeCode The key code for input towards the negative of the axis.
	 * @returns - `1`, if only `positiveCode` is pressed,
	 * - `-1`, if only `negativeCode` is pressed,
	 * - `0`, if none or both key codes are pressed.
	 */
	twoCodesToAxis(positiveCode: string, negativeCode: string): number {
		let axisValue = 0;

		if (this.isCodePressed(positiveCode)) {
			axisValue += 1;
		}

		if (this.isCodePressed(negativeCode)) {
			axisValue -= 1;
		}

		return axisValue;
	}

	/** Event triggered when a key is pressed. */
	keyPressed = new TypedEvent<KeyboardEvent>();

	/** Event triggered when a key is released. */
	keyReleased = new TypedEvent<KeyboardEvent>();

	/** Event triggered when a key changed. */
	keyChanged = new TypedEvent<KeyboardEvent>();

	/**
	 * Register when a key is pressed down.
	 *
	 * @param ev The keyboard event triggered by a key being pressed down.
	 */
	private onKeyDown(ev: KeyboardEvent): void {
		this.#pressedCodes.set(ev.code, ev);
		this.#pressedKeys.add(ev.key);

		this.keyPressed.emit(ev);
		this.keyChanged.emit(ev);
	}

	/**
	 * Register when a key is released.
	 *
	 * @param ev The keyboard event triggered by a key being released.
	 */
	private onKeyUp(ev: KeyboardEvent): void {
		const hasDeletedCode = this.#pressedCodes.delete(ev.code);
		const hasDeletedKey = this.#pressedKeys.delete(ev.key);

		// Only emit events if the internal state had the keys currently pressed
		if (hasDeletedCode || hasDeletedKey) {
			this.keyReleased.emit(ev);
			this.keyChanged.emit(ev);
		}
	}

	/**
	 * Handler for blur event that releases all currently held keys with a simulated keyup event.
	 */
	private onBlur(): void {
		for (const [code, keyDownEvent] of this.#pressedCodes.entries()) {
			const keyUpEvent = new KeyboardEvent("keyup", {
				code,
				key: keyDownEvent.key,
				keyCode: keyDownEvent.keyCode,
			});

			this.onKeyUp(keyUpEvent);
		}
	}

	/**
	 * Attach an element to listen to events.
	 *
	 * @param element The HTML element to use for event listening.
	 */
	attach(element: HTMLElement): void {
		this.detach();

		element.addEventListener("keydown", this.onKeyDown);
		element.addEventListener("keyup", this.onKeyUp);
		element.addEventListener("blur", this.onBlur);

		this.#element = element;
	}

	/**
	 * Detach the current element used for event listening.
	 *
	 * After this, you need to use `.attach` again to commence event listening.
	 */
	detach(): void {
		if (this.#element) {
			this.#element.removeEventListener("keydown", this.onKeyDown);
			this.#element.removeEventListener("keyup", this.onKeyUp);
			this.#element.removeEventListener("blur", this.onBlur);

			this.#element = undefined;
		}
	}

	/** Clean up all used resources. */
	dispose(): void {
		this.detach();
	}
}
