import {
  ButtonBaseActions,
  DialogActions,
  DialogContent,
  Dialog as MUIDialog,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import { DialogProps as MUIDialogProps } from "@mui/material/Dialog";
import { PropsWithChildren, ReactNode, RefObject, useMemo } from "react";
import { FaroButton } from "../button/faro-button";
import { ColorString, neutral } from "../colors";
import { FaroIconButton } from "../icon-button/faro-icon-button";
import { CloseCancelErrorIcon } from "../icons/icons";
import { FaroText } from "../text/faro-text/faro-text";
import { TruncatedFaroText } from "../text/truncated-text";
import { FaroDialogSizes, FaroDialogVariants } from "./faro-dialog-constants";

/** Colors that needs to be customized between the light/dark variant of the FARO Dialog */
type FaroDialogPalette = {
  /** Main background color */
  background: ColorString;

  /** Foreground color, used mostly by the text and the title*/
  foreground: ColorString;
};

const LIGHT_FARO_DIALOG_PALETTE: FaroDialogPalette = {
  background: neutral[50],
  foreground: neutral[800],
};

const DARK_FARO_DIALOG_PALETTE: FaroDialogPalette = {
  background: neutral[999],
  foreground: neutral[100],
};

export type FaroDialogProps = Pick<
  MUIDialogProps,
  "open" | "onClose" | "sx"
> & {
  /** Title of the dialog */
  title: ReactNode;

  /**
   * Setting to true will disable the confirm button
   *
   * @default false
   */
  isConfirmDisabled?: boolean;

  /**
   * Text to display on the confirm button
   *
   * @default "Confirm"
   */
  confirmText?: string;

  /**
   * Text to display on the cancel button
   *
   * @default "Cancel"
   */
  cancelText?: string;

  /**
   * called when the user click Confirm
   * if not defined the confirm button will not be shown
   */
  onConfirm?(): void;

  /**
   * Called when the user click Cancel
   * if undefined cancel button will NOT be shown.
   */
  onCancel?(): void;

  /**
   * Called when the user close the dialog clicking outside, pressing esc or using the X on the top right
   * if undefined the X on the top right will not be shown.
   */
  onClose?: MUIDialogProps["onClose"];

  /**
   * Variant of the dialog based on which the look and feel changes
   *
   * @default "info"
   */
  variant?: FaroDialogVariants;

  /**
   * Show a spinner together with the text in the confirmation button
   *
   * @default false
   */
  showSpinner?: boolean;

  /**
   * Disable all the UI and events in the dialog
   *
   * @default false
   */
  disabled?: boolean;

  /** A handle on the cancel button actions used to trigger it's visual focus */
  cancelButtonActions?: RefObject<ButtonBaseActions>;

  /**
   * Size of the dialog
   *
   * @default "m"
   */
  size?: `${FaroDialogSizes}`;

  /**
   * If true the X button, that closes the dialog if clicked, in the top right corner will be shown.
   * It's always shown for size L.
   *
   * @default false
   */
  showXButton?: boolean;

  /** true to use the dark variant of the component @default false */
  dark?: boolean;

  /**
   * Hide the backdrop behind the dialog, removing its opacity effect.
   */
  hideBackdrop?: boolean;
};

/**
 * @returns a dialog to ask the user to confirm or cancel an action
 */
export function FaroDialog({
  title,
  children,
  open,
  onConfirm,
  isConfirmDisabled = false,
  confirmText = "Confirm",
  cancelText = "Cancel",
  onCancel,
  onClose,
  variant = "info",
  size = FaroDialogSizes.m,
  showSpinner = false,
  disabled = false,
  cancelButtonActions,
  showXButton = false,
  dark = false,
  hideBackdrop = false,
  sx,
}: PropsWithChildren<FaroDialogProps>): JSX.Element {
  const colors = dark ? DARK_FARO_DIALOG_PALETTE : LIGHT_FARO_DIALOG_PALETTE;

  const sizeStyles = SIZE_STYLE_MAP[size];

  const shouldShowXButton = useMemo(
    () => size === FaroDialogSizes.l || showXButton,
    [showXButton, size],
  );

  return (
    <MUIDialog
      open={open}
      // Make sure the dialog is not closable while the spinner is shown
      onClose={showSpinner ? undefined : onClose}
      sx={sx}
      PaperProps={{
        sx: {
          p: "2rem",
          background: colors.background,
          color: colors.foreground,
          ...sizeStyles,
        },
      }}
      hideBackdrop={hideBackdrop}
    >
      <Stack
        direction="column"
        sx={{
          pointerEvents: disabled ? "none" : "auto",
          opacity: disabled ? 0.5 : 1,
          overflow: "auto",
        }}
      >
        <FaroText variant="heading20">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {title && (
              <TruncatedFaroText
                variant="heading20"
                component="span"
                color={colors.foreground}
              >
                {title}
              </TruncatedFaroText>
            )}
            {onClose && shouldShowXButton && (
              <FaroIconButton
                size="xs"
                color={colors.foreground}
                // The signature of onClose is defined by MUI and only allow "escapeKeyDown" and "backdropClick"
                // as a X button is not part of the MUI specs
                // I'm using escapeKeyDown as a reason for the dialog close here for now
                onClick={(ev) => onClose(ev, "escapeKeyDown")}
              >
                <CloseCancelErrorIcon sx={{ color: "black" }} />
              </FaroIconButton>
            )}
          </Stack>
        </FaroText>

        <DialogContent
          sx={{
            p: 0,
            marginTop: "1.5rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {children}
        </DialogContent>

        {(onCancel ?? onConfirm) && (
          <DialogActions sx={{ p: 0, marginTop: "2rem" }}>
            {onCancel && (
              <FaroButton
                aria-label="cancel"
                variant={variant === "info" ? "ghost" : "secondary"}
                dark={dark}
                action={cancelButtonActions}
                onClick={onCancel}
                disabled={disabled}
              >
                {cancelText}
              </FaroButton>
            )}

            {onConfirm && (
              <FaroButton
                aria-label="confirm"
                variant={variant === "info" ? "primary" : "destructive"}
                dark={dark}
                onClick={onConfirm}
                disabled={isConfirmDisabled || disabled}
                isLoading={showSpinner}
              >
                {confirmText}
              </FaroButton>
            )}
          </DialogActions>
        )}
      </Stack>
    </MUIDialog>
  );
}

// Specific style for each dialog size as defined in the FARO Design System
const SIZE_STYLE_MAP: Record<FaroDialogSizes, SxProps<Theme>> = {
  [FaroDialogSizes.s]: {
    width: "19.375rem",
    maxWidth: "19.375rem",
    maxHeight: "45vh",
  },
  [FaroDialogSizes.m]: {
    width: "35rem",
    maxWidth: "35rem",
    maxHeight: "85vh",
  },
  [FaroDialogSizes.l]: {
    width: "54.375rem",
    maxWidth: "54.375rem",
    maxHeight: "95vh",
  },
};
