import {
  IElementBase,
  IElementType,
  IElementTypeHint,
  WithHint,
} from "./i-element-base";
import {
  GUID,
  IQuat,
  ISOTimeString,
  IVec3,
  ProjectIntegrations,
  ProjectSettings,
  SlideContainerMarkupSettings,
} from "./properties";

/**
 * Docs for the API types can be found here:
 * https://v2.project.api.staging.holobuilder.com/swagger/index.html
 */

/** Union of fully typed IElements. Use e.g. to discriminate based on type field. */
export type IElement =
  | IElementProjectRoot
  | IElementGroup
  | IElementSection
  | IElementTimeSeries
  | IElementImgSheet
  | IElementImgSheetTiled
  | IElementImg2d
  | IElementImg360
  | IElementImgCube
  | IElementModel3D
  | IElementModel3dStream
  | IElemLink
  | IElementPointCloud
  | IElementPointCloudCpe
  | IElementPointCloudE57
  | IElementPointCloudGeoSlam
  | IElementPointCloudLaz
  | IElementPointCloudStream
  | IElementPointCloudStreamWebShare
  | IElementUrlLink
  | IElementMarkup
  | IElementMarkupBim360
  | IElementMarkupProcoreRfi
  | IElementMarkupProcoreObservation
  | IElementAudioAttachment
  | IElementAttachment
  | IElementMeasureWall
  | IElementMeasureLine
  | IElementAiWall
  | IElementAiMaterialDetection
  | IElementRoomLayout
  | IElementFloorLayout
  | IElementDropDownMarkupFieldTemplate
  | IElementUserDirectoryMarkupFieldTemplate
  | IElementMarkupTemplate
  | IElementDateTimeMarkupFieldTemplate
  | IElementVideo360
  | IElementVideo2d
  | IElementMarkupPolygon
  | IElementMeasurePolygon
  | IElementAnalysis
  | IElementUnknown;

/** An IElement that is not already mapped to a more specific type in this library */
export interface IElementUnknown extends IElementBase {
  /** As this type is unknown the type value will not match one of the expected value in the IElementType enum */
  type: string;
}

/** The root of the project. */
export interface IElementProjectRoot extends IElementBase {
  type: IElementType.projectRoot;

  /** External id of this project, managed by the Core API. */
  externalId: string;

  metaDataMap?: {
    projectSettings?: ProjectSettings;
    slideContainerMarkupSettings?: SlideContainerMarkupSettings;
    projectIntegrations?: ProjectIntegrations;
  } | null;
}

/**
 * Similar to Sections but indicates a logical collection of
 * the project and not like Sections a physical collection. Logical collections
 * are for example a list of TimeTravel Img360s or a list of
 * different ImgSheets representing the same Section
 */
export interface IElementGroup extends IElementBase {
  type: IElementType.group;

  /**
   * If true this indicates that typically only one child of
   * the group should be active at the same time, e.g. for timetravel groups
   */
  xOr: boolean;
}

/** The group for an Area where the Rooms are placed in */
export type IElementRoomsGroup = WithHint<
  IElementGroup,
  IElementTypeHint.rooms
>;

/**
 * Similar to Group but inidicates a physical collection of
 * the project and not like Groups a logical collection.
 */
export interface IElementSection extends IElementBase {
  type: IElementType.section;
}

/**
 * Similar to Sections but inidicates a logical collection of
 * the project and not like Sections a physical collection.
 * Logical collectionsare for example a list of TimeTravel Img360s or a list of
 * different ImgSheets representing the same Section.
 */
export interface IElementTimeSeries extends IElementBase {
  type: IElementType.timeSeries;
}

/** A data session which contains multiple captured datasets. */
export type IElementTimeSeriesDataSession = WithHint<
  IElementTimeSeries,
  IElementTypeHint.dataSession
>;

/** Child TimeSeries of a Room */
export type IElementTimeSeriesRoom = WithHint<
  IElementTimeSeries,
  IElementTypeHint.room
>;

/** A data session which contains multiple captured datasets. */
export type IElementSectionDataSession = WithHint<
  IElementSection,
  IElementTypeHint.dataSession
>;

/** A Section of type Area */
export type IElementAreaSection = WithHint<
  IElementSection,
  IElementTypeHint.area
>;

/** A Section of type Room */
export type IElementRoomSection = WithHint<
  IElementSection,
  IElementTypeHint.room
>;

/**
 * An image sheet, e.g. a floorplan.
 *
 * The difference to an Img2d is that an ImgSheet has a physical
 * size in the real world (the `size` attribute).
 */
export interface IElementImgSheet extends IElementWithPixelSize {
  type: IElementType.imgSheet;

  /**
   * The size of the sheet, correlates with the pose.pos values of
   * its Children, so the size of the sheet will be multiplied to
   * the positions of the children when they are added to the sheet and only know their
   * relative position on the section sheet (in 0-1 space).
   *
   * Setting the size to 100 means that the sheet only has a
   * relative size and its absolute size in meters is not determined yet.
   * This means that only the _aspect ratio_ is meaningful, not the actual size.
   */
  size?: IVec3;
}

export type ImgSheetLevelsOfDetailSource = {
  /** position of the tile in the x direction */
  x: number;

  /** position of the tile in the y direction */
  y: number;

  /** url for the tile */
  source: string;
};

/** Description of a LevelsOfDetails tree used by FloorPlans */
export type ImgSheetLevelsOfDetail = {
  /** Current level in the LOD structure */
  level: number;

  /** The number of tiles along the x direction */
  dimX: number;

  /** The number of tiles along the y direction */
  dimY: number;

  /** The source of this tile */
  sources: ImgSheetLevelsOfDetailSource[];
};

/** Custom type defining relevant properties of a tiled floor plan */
export interface IElementImgSheetTiled
  extends Omit<
    IElementWithPixelSize,
    "uri" | "signedUri" | "signedUriExpiresOn"
  > {
  type: IElementType.imgSheetTiled;

  // TODO: Remove levelsOfDetail property (https://faro01.atlassian.net/browse/SWEB-4590)
  /**
   * The list of levels describing the floor plan hierarchy
   *
   * @deprecated use the json blob defined in the uri property instead
   */
  levelsOfDetail?: ImgSheetLevelsOfDetail[];

  /**
   * The size of the sheet, correlates with the pose.pos values of
   * its Children, so the size of the sheet will be multiplied to
   * the positions of the children when they are added to the sheet and only know their
   * relative position on the section sheet (in 0-1 space).
   *
   * Setting the size to 100 means that the sheet only has a
   * relative size and its absolute size in meters is not determined yet.
   * This means that only the _aspect ratio_ is meaningful, not the actual size.
   */
  size?: IVec3;

  /**
   * ImgSheetTiled uri props point to a ImgSheetLevelsOfDetails object payload json
   *
   * @deprecated use signedUri instead
   */
  uri?: string;

  /**
   * ImgSheetTiled signedUrl props point to a ImgSheetLevelsOfDetails object payload json
   */
  signedUri?: string;

  /**
   * Expiration date for the signedUri in this element
   */
  signedUriExpiresOn?: ISOTimeString;
}

export type IElementGenericImgSheet = IElementImgSheet | IElementImgSheetTiled;

export interface IElementWithUri extends IElementBase {
  /**
   * The Uniform Resource Identifier pointing to the central resource of
   * the IElement, for example an Image IElement would point to it's image URL, a
   * reference to another IElement contains the uuid of the target IElement, a file
   * attachment points to the URL of the target file, an integration link to an
   * external integration points to the unique ID in the external system.
   *
   * @deprecated use signedUri instead
   */
  uri: string;

  /**
   * The Uniform Resource Identifier pointing to the central resource of
   * the IElement, for example an Image IElement would point to it's image URL, a
   * reference to another IElement contains the uuid of the target IElement, a file
   * attachment points to the URL of the target file, an integration link to an
   * external integration points to the unique ID in the external system.
   *
   * To allow only user with the proper permissions to access the resource the uri is signed.
   * The signature is a set of extra url parameters that authenticate the current user for a limited period.
   * To know when the signature will expire @see signedUriExpiresOn
   * An updated signature can be requested trough a ProjectApi call
   */
  signedUri?: string;

  /**
   * Expiration date for the @see signedUri in this element
   */
  signedUriExpiresOn?: ISOTimeString;
}

export interface IElementWithFileUri extends IElementWithUri {
  /**
   * If a file is placed behind the uri the Md5 hash should contain the
   * files md5Hash encoded in base 16 (hexadecimal).
   */
  md5Hash?: string | null;

  /** Size of the file, in bytes. */
  fileSize?: number | null;

  /**
   * The name of the file, e.g. "MyFile1.txt".
   *
   * If possible should contain the file type at the end of the name.
   */
  fileName?: string | null;
}

export interface IElementWithPixelSize extends IElementWithFileUri {
  /**
   * The width in pixels of the original image, contained in the
   * IElemWithUri.uri field of the object.
   */
  pixelWidth: number;

  /**
   * The height in pixels of the original image, contained in the
   * IElemWithUri.uri field of the object.
   */
  pixelHeight: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IElementImgSliced extends IElementWithPixelSize {
  // empty on purpose to match native class structure
}

/** Description of a LevelsOfDetails tree used by Img360 */
export type Img360LevelsOfDetail = {
  /** Current level in the LOD structure */
  level: number;

  /** The number of tiles along the x direction */
  dimX: number;

  /** The number of tiles along the y direction */
  dimY: number;

  /** The sources of this tile */
  sources: string[];

  /** The signed urls for the tiles */
  signedSources: string[];

  /** The expire date for the signed urls signatures */
  signedSourcesExpiresOn: ISOTimeString;
};

export interface IElementImg360 extends IElementImgSliced {
  type: IElementType.img360;

  /**
   * Contains a json structure referencing the single 1x1 slice image of the original
   * image, which represents the version of the image with the lowest resolution
   */
  json1x1: string;

  /**
   * Contains a json structure referencing the two 2x1 slices of the
   * image, which is for many 360 cameras like the Theta V already the highest available
   * version of the image slices (so no 4x2 slices).
   */
  json2x1?: string | null;

  /**
   * Some cameras like DSLRs have such a high original resolution that they are
   * split into 4x2 slices or even 8x4 slices, which is the maximum nr of textures a
   * single 360 image is composed of so far in our system.
   */
  json4x2?: string | null;

  /**
   * Some cameras like DSLRs have such a high original resolution that they are
   * split into 4x2 slices or even 8x4 slices, which is the maximum nr of textures a
   * single 360 image is composed of so far in our system.
   */
  json8x4?: string | null;

  /** The list of levels describing the panorama image tiles hierarchy */
  levelsOfDetail?: Img360LevelsOfDetail[];

  /** To indicate whether the rotation of an image is accurate (WebShare, VideoMode, SiteAI correction) or not (JWA) */
  isRotationAccurate?: boolean;

  metaDataMap?: Record<string, unknown> | null;

  /** The ID of the associated room section of a duplicate element */
  targetId?: GUID | null;
}

/** A depth map to provide depth info to a pano image */
export interface IElementDepthMap extends IElementImgSliced {
  type: IElementType.depthMap;
}

/** An image cube. */
export interface IElementImgCube extends IElementImgSliced {
  type: IElementType.imgCube;

  /**
   * Cubemaps consist of 6 images for the 6 sides of the cubes, this json structure
   * represents the reference to the 6 HIGH resolution textures for these 6 sides.
   */
  json6x1High: string;

  /**
   * Cubemaps consist of 6 images for the 6 sides of the cubes, this json structure
   * represents the reference to the 6 LOW resolution textures for these 6 sides.
   */
  json6x1Low: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IElementImg2d extends IElementWithPixelSize {
  type: IElementType.img2d;
}

/** A 3D model. */
export interface IElementModel3D extends IElementWithFileUri {
  type: IElementType.model3d;
}

/** A link to another iElement. */
export interface IElemLink extends IElementBase {
  type: IElementType.iElemLink;

  /**
   * IElement Target of the link
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  target_Id: GUID;
}

/** A DataSet for VideoWalk. */
export interface IElementDataSetVideoWalk extends IElementSection {
  typeHint: IElementTypeHint.dataSetVideoWalk;
}

/** A VideoRecording or a DataSet for VideoWalk. */
export type IElementGenericVideoRecording =
  | IElementSection
  | IElementDataSetVideoWalk;

/** All the types that matches to valid point clouds */
export const VALID_POINT_CLOUD_ELEMENT_TYPES: string[] = [
  IElementType.pointCloudLaz,
  IElementType.pointCloudCpe,
  IElementType.pointCloudE57,
  IElementType.pointCloudGeoSlam,
];

/**
 * A generic untyped point cloud
 */
export interface IElementPointCloud extends IElementWithUri {
  type: IElementType.pCloud;
}

/**
 * A PointCloud in CPE format
 */
export interface IElementPointCloudCpe extends IElementWithFileUri {
  type: IElementType.pointCloudCpe;
}

/**
 * A PointCloud in E57 format
 */
export interface IElementPointCloudE57 extends IElementWithFileUri {
  type: IElementType.pointCloudE57;
}

/**
 * A PointCloud in GeoSlam format
 */
export interface IElementPointCloudGeoSlam extends IElementWithFileUri {
  type: IElementType.pointCloudGeoSlam;
}

/**
 * A PointCloud in compressed LAS format
 */
export interface IElementPointCloudLaz extends IElementWithFileUri {
  type: IElementType.pointCloudLaz;
}

/**
 * A generic type defining the currently supported point clouds
 */
export type IElementGenericPointCloud =
  | IElementPointCloudCpe
  | IElementPointCloudE57
  | IElementPointCloudGeoSlam
  | IElementPointCloudLaz;

/**
 * A pointcloud stream from a webshare project
 */
export interface IElementPointCloudStreamWebShare extends IElementWithUri {
  type: IElementType.pointCloudStreamWebShare;

  /** The name of the project in the webshare data */
  webShareProjectName: string;

  /** A uuid for the pointcloud in the webshare project */
  webShareCloudId: string;

  /** Entity id of the project in the webshare */
  webShareEntityId: string;
}

/**
 * A pointcloud stream
 */
export interface IElementPointCloudStream extends IElementWithUri {
  type: IElementType.pointCloudStream;
}

/** A 3D model stream. */
export interface IElementModel3dStream extends IElementWithFileUri {
  type: IElementType.model3dStream;
}

/** Generic Model3d type that includes the Model3D and Model3dStream */
export type IElementGenericModel3d = IElementModel3D | IElementModel3dStream;

/**
 * A generic type defining the currently supported point cloud streams
 * (Webshare and Potree).
 */
export type IElementGenericPointCloudStream =
  | IElementPointCloudStream
  | IElementPointCloudStreamWebShare;

/**
 * A generic type defining the currently supported point cloud and CAD streams
 */
export type IElementGenericStream =
  | IElementGenericPointCloudStream
  | IElementModel3dStream;

/**  A Url Link. */
export interface IElementUrlLink extends IElementWithUri {
  type: IElementType.urlLink;
}

/** A Markup */
export interface IElementMarkup extends IElementBase {
  type: IElementType.markup;

  /** The id of the markup template used for this markup */
  templateId?: GUID | null;
}

/** Possible states for a polygon point coming from WebShare conversions */
export enum PolygonPointState {
  /** The point is valid */
  valid = "Valid",

  /** The point is invalid */
  invalid = "Invalid",

  /** The point state is unknown */
  unknown = "Unknown",
}

/** A single point of a MarkupPolygon or a MeasurePolygon */
export type PolygonPoint = IVec3 & {
  // TODO: Remove number and null from the valid polygon point states - https://faro01.atlassian.net/browse/SWEB-4163
  /** A state flag used during the Webshare conversion to keep the webshare state information */
  state?: PolygonPointState | number | null;
};

/** An IElement representing an annotation in a project, used to convert single points annotations from Webshare */
export interface IElementMarkupPolygon extends IElementBase {
  type: IElementType.markupPolygon;

  /** The position of the annotation in the space */
  points: PolygonPoint[];
}

/** An IElement representing a measurement in a project */
export interface IElementMeasurePolygon extends IElementBase {
  type: IElementType.measurePolygon;

  /** The points of the measurement */
  points: PolygonPoint[];

  /** True if the measurement is a closed polygon */
  isClosed: boolean;
}

export type IElementGenericAnnotation =
  | IElementImg2d
  | IElementModel3D
  | IElementMarkupPolygon
  | IElementMeasurePolygon;

/**
 * A markup for Autodesk BIM 360 Issue.
 *
 * To obtain the values of this markup, you need to query the integration.
 */
export interface IElementMarkupBim360 extends IElementBase {
  type: IElementType.markupBim360;

  /** The Autodesk Id of the external Issue. It is valid in the linked Autodesk project (see metadata in project IElement). */
  externalIssueId: string;
}

/**
 * A markup for Procore Observation
 *
 * To obtain the values of this markup, you need to query the integration.
 */
export interface IElementMarkupProcoreObservation extends IElementBase {
  type: IElementType.markupProcoreObservation;

  /** The Procore Id of the Observation. It is valid in the linked Procore project (see metadata in project IElement). */
  externalIssueId: string;
}

/**
 * A markup for Procore RFI (Request for Information)
 *
 * To obtain the values of this markup, you need to query the integration.
 */
export interface IElementMarkupProcoreRfi extends IElementBase {
  type: IElementType.markupProcoreRfi;

  /** The Procore Id of the RFI. It is valid in the linked Procore project (see metadata in project IElement). */
  externalIssueId: string;
}

/**
 * A markup for a ACC (Autodesk Construction Cloud) Issue
 *
 * To obtain the values of this markup, you need to query the integration.
 */
export interface IElementMarkupAccIssue extends IElementBase {
  type: IElementType.markupAccIssue;

  /** The ACC Id of the Issue. It is valid in the linked ACC project (see metadata in project IElement). */
  externalIssueId: string;
}

/**
 * A markup for ACC (Autodesk Construction Cloud) RFI (Request for Information)
 *
 * To obtain the values of this markup, you need to query the integration.
 */
export interface IElementMarkupAccRfi extends IElementBase {
  type: IElementType.markupAccRfi;

  /** The ACC Id of the RFI. It is valid in the linked ACC project (see metadata in project IElement). */
  externalIssueId: string;
}

/** External Markups */
export type ExternalMarkupIElement =
  | IElementMarkupBim360
  | IElementMarkupProcoreObservation
  | IElementMarkupProcoreRfi
  | IElementMarkupAccIssue
  | IElementMarkupAccRfi;

/** Markup types */
export type MarkupIElement = IElementMarkup | ExternalMarkupIElement;

/** Base interface for an Markup Field IElement */
interface IElementMarkupField extends IElementBase {
  /**
   * The id of the corresponding FieldTemplate.
   * The templates can be found in the `Project Root>Markup Templates` group.
   */
  templateId: GUID;
}

/** IElements that hold a single field of information in a markup */
export type IElementGenericMarkupField =
  | IElementDateTimeMarkupField
  | IElementUserDirectoryMarkupField
  | IElementDropDownMarkupField;

/** A markup annotation */
export interface IElementMarkupCommand extends IElementMarkup {
  typeHint: IElementTypeHint.command;
}

/** An annotation with a link to another Element */
export interface IElemLinkCommand extends IElemLink {
  typeHint: IElementTypeHint.command;
}

/** A Pdf annotation */
export interface IElementPdfCommand extends IElementWithFileUri {
  type: IElementType.pdfAttachment;
  typeHint: IElementTypeHint.command;
}

/** An url link annotation */
export interface IElementUrlLinkCommand extends IElementBase {
  type: IElementType.urlLink;
  typeHint: IElementTypeHint.command;
}

/** An image annotation */
export interface IElementImageCommand extends IElementWithFileUri {
  type: IElementType.img2d;
  typeHint: IElementTypeHint.command;
}

/** An audio attachment */
export interface IElementAudioAttachment extends IElementWithFileUri {
  type: IElementType.audioAttachment;
}

/** An attachment */
export interface IElementAttachment extends IElementWithFileUri {
  type: IElementType.attachment;
}

/** Measurement wall */
export interface IElementMeasureWall extends IElementBase {
  type: IElementType.measureWall;

  /**
   * Coordinates of measurement corners in the 360 image
   * Those are relative coordinates
   */
  point2: IVec3;
  point3: IVec3;
}

/** Measure Line */
export interface IElementMeasureLine extends IElementBase {
  type: IElementType.measureLine;

  point2: IVec3;
}

/** Wall detected by AI */
export interface IElementAiWall extends IElementBase {
  type: IElementType.aiWall;

  /** Coordinates of wall corners in the 360 image (not floorplan). These are relative coordinates */
  point2: IVec3;
  point3: IVec3;
  point4: IVec3;

  /** The ID of the associated wall object defined in the respective FloorLayout element. */
  sheetWallId: GUID;

  /** The ID of the corner object associated to the first point, defined in the respective FloorLayout element */
  sheetCornerId1: GUID;

  /** The ID of the corner object associated to the second point, defined in the respective FloorLayout element */
  sheetCornerId2: GUID;

  /** The ID of the corner object associated to the third point, defined in the respective FloorLayout element */
  sheetCornerId3: GUID;

  /** The ID of the corner object associated to the fourth point, defined in the respective FloorLayout element */
  sheetCornerId4: GUID;
}

/** Material detected by AI */
export interface IElementAiMaterialDetection extends IElementBase {
  type: IElementType.aiMaterialDetection;

  /** The ID of the material detected on the parent AiWall. */
  materialId: string;

  /**
   * Percentage of the given material present on the parent AiWall.
   * Defined as a factor in the range of 0 to 1.
   * The sum of the percentage factors of all AiMaterialDetection child elements attached
   * to an AiWall should always equal 1 (100%).
   */
  percentage: number;
}

/** Room layout that is made by walls */
export interface IElementRoomLayout extends IElementBase {
  type: IElementType.roomLayout;
}

/** Sheet layout introducing all the corners and the walls */
export interface IElementFloorLayout extends IElementBase {
  type: IElementType.floorLayout;

  /** All the corners in the floorplan */
  corners: Record<GUID, { x: number; y: number }>;

  /**
   * All the walls in the floorplan
   * It maps the wall ID to the two IDs belonging to the start and end corner of the wall
   */
  walls: Record<GUID, [[GUID, GUID], Record<string, unknown>]>;
}

/** The status of the markup */
export interface IElementDropDownMarkupFieldTemplate extends IElementBase {
  type: IElementType.dropDownMarkupFieldTemplate;

  /** Indicates if the field has to be put in or not by the user when a new markup is created */
  mandatory: boolean;

  /** Different values for the dropdown, nullish if not defined  */
  values?: string[] | null;
}

/** The status of the markup */
export interface IElementDropDownMarkupField extends IElementMarkupField {
  type: IElementType.dropDownMarkupField;

  /** The status of task, nullish if not defined */
  value?: string | null;
}

/** The assignee of the markup  */
export interface IElementUserDirectoryMarkupFieldTemplate extends IElementBase {
  type: IElementType.userDirectoryMarkupFieldTemplate;

  /** Indicates if the field has to be put in or not by the user when a new markup is created */
  mandatory: boolean;
}

/** The assignees of the markup  */
export interface IElementUserDirectoryMarkupField extends IElementMarkupField {
  type: IElementType.userDirectoryMarkupField;

  /** The list of ids of the assignees, nullish if not defined  */
  values?: GUID[] | null;
}

/** Advanced markup */
export interface IElementMarkupTemplate extends IElementBase {
  type: IElementType.markupTemplate;
}

/** The issue due date of the markup */
export interface IElementDateTimeMarkupFieldTemplate extends IElementBase {
  type: IElementType.dateTimeMarkupFieldTemplate;

  /** Indicates if the field has to be put in or not by the user when a new markup is created */
  mandatory: boolean;
}

/** The issue due date of the markup */
export interface IElementDateTimeMarkupField extends IElementMarkupField {
  type: IElementType.dateTimeMarkupField;

  /** The due date of the markup */
  value: ISOTimeString;
}

/** A 360 video resource. Used for example in VideoRecordings element of video mode. */
export interface IElementVideo360 extends IElementWithPixelSize {
  type: IElementType.video360;

  /** The video duration in milliseconds */
  durationInMilliseconds: number;
}

export interface IElementVideo2d extends IElementWithPixelSize {
  type: IElementType.video2d;

  /** The video duration in milliseconds */
  durationInMilliseconds: number;
}

export interface IElementClippingBox extends IElementBase {
  type: IElementType.clippingBox;

  /** The size of the clipping box */
  size: IVec3;
}

export interface IElementVolume extends IElementClippingBox {
  type: IElementType.clippingBox;

  /** The size of the clipping box */
  typeHint: IElementTypeHint.volume;
}

/** Type hints of all supported datasets. */
export const GENERIC_DATASET_TYPE_HINTS = [
  IElementTypeHint.dataSetPCloudUpload,
  IElementTypeHint.dataSetGeoSlam,
  IElementTypeHint.dataSetWs,
  IElementTypeHint.dataSetVideoWalk,
  IElementTypeHint.dataSetFocus,
  IElementTypeHint.dataSetEls,
] as const;

/** Any supported dataset IElement. */
export type IElementGenericDataset = WithHint<
  IElementSection,
  (typeof GENERIC_DATASET_TYPE_HINTS)[number]
>;

/** Analysis IElement */
export interface IElementAnalysis extends IElementWithFileUri {
  type: IElementType.analysis;
}

/**
 * @returns true if the IElement is geo-referenced (i.e. has identify transformation independent from parents)
 * @param element IElement being tested
 */
export function isGeoReferencedElement(element: IElement | undefined): boolean {
  return (
    !!element?.pose?.isWorldPose &&
    isNullOrZeroVec3(element.pose.pos) &&
    isNullOrIdentityQuaternion(element.pose.rot) &&
    isNullOrUnitVec3(element.pose.scale)
  );
}

/**
 * Test whether a IVec3 vector is either null or exactly [0,0,0].
 *
 * @param vec3 IVec3 to be tested
 * @returns true if vec3 is null or [0,0,0]
 */
function isNullOrZeroVec3(vec3: IVec3 | null): boolean {
  return !vec3?.x && !vec3?.y && !vec3?.z;
}

/**
 * Test whether a IVec3 vector is either null or exactly [1,1,1].
 *
 * @param vec3 IVec3 to be tested
 * @returns true if vec3 is null or [1,1,1]
 */
function isNullOrUnitVec3(vec3: IVec3 | null): boolean {
  return !vec3 || (vec3.x === 1 && vec3.y === 1 && vec3.z === 1);
}

/**
 * Test whether a IQuat quaternion is either null or exactly [0,0,0,1] (identity)
 *
 * @param vec4 IQuat to be tested
 * @returns true if vec4 is null or [0,0,0,1]
 */
function isNullOrIdentityQuaternion(vec4: IQuat | null): boolean {
  return !vec4?.x && !vec4?.y && !vec4?.z && (!vec4 || vec4.w === 1);
}

/**
 * used to check if Area is aligned.
 * considering Area aligned if it has a pose, rot and pos not null
 * and any element of rot different from identity or pos is not [0,0,0]
 * scale is not taken in consideration
 *
 * @returns true if the IElementSection is aligned
 * @param area IElementSection being tested
 */
export function isAreaAligned(area: IElementSection): boolean {
  return (
    !!area.pose &&
    ((!!area.pose.pos && !isNullOrZeroVec3(area.pose.pos)) ||
      (!!area.pose.rot && !isNullOrIdentityQuaternion(area.pose.rot)))
  );
}

/** Type hints of generic data session sections that is listed in Sphere XG UI */
export const GENERIC_DATA_SESSION_TYPE_HINTS = [
  IElementTypeHint.dataSetFocus,
  IElementTypeHint.dataSetEls,
  IElementTypeHint.dataSession,
] as const;

/** Any supported dataset IElement. */
export type IElementGenericDataSession = WithHint<
  IElementSection,
  (typeof GENERIC_DATA_SESSION_TYPE_HINTS)[number]
>;
