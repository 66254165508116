import { GUID } from "@faro-lotv/ielement-types";
import { clientIdHeader } from "../utils/headers";
import { Token } from "./auth-types";
import { decodeJwtPayload } from "./jwt-utils";

interface IGetToken {
  /** The JWT received from the backend */
  token: Token;
}

interface IJWTokenRequestPayload {
  /** The scopes the JWT has to provide for the user */
  scopes: string[];
  data: {
    projects?: Array<{
      /** ID of the project to work with */
      id: GUID;
    }>;
  };
}

/**
 * Responsible for managing authentication tokens from the Core API.
 *
 * An authentication with the Core API is needed for practically all Holobuilder/Sphere XG backends.
 * If you are using React, use the `useCoreApiTokenProvider` hook to obtain a `TokenProvider`
 * which uses the `CoreApiTokenManager`.
 *
 * The manager caches the current JWT and requests a new token if the current one is expired.
 */
export class CoreApiTokenManager {
  /** Cached authentication token to reuse for future requests. */
  #token: Token | undefined;

  /**
   * Cached request to request a new token.
   * We save this to avoid requesting multiple tokens at the same time.
   */
  #tokenRequest: Promise<Token> | undefined;

  /** Payload to use when requesting a new token */
  #tokenRequestPayload: IJWTokenRequestPayload;

  /**
   * The session token in the case we are logged in with SSO
   * Please check: https://faro01.atlassian.net/browse/JWA3-813
   */
  #sessionToken: Token | undefined;

  /**
   * Creating a TokenManager instance
   *
   * @param coreApiBaseUrl URL of the HB Core API
   * @param projectId ID of the project the user wants to access
   * @param clientId A string to identify a backend client in the format client/version
   */
  constructor(
    private coreApiBaseUrl: URL,
    private projectId: GUID,
    private clientId?: string,
  ) {
    this.#tokenRequestPayload = {
      // Scope to use in order to provide a user access to a project
      scopes: ["user:project"],

      data: {
        projects: [{ id: this.projectId }],
      },
    };
  }

  /**
   * Setting a sessionToken for usage when an SSO login has taken place.
   */
  set sessionToken(sessionToken: Token | undefined) {
    this.#sessionToken = sessionToken;
  }

  /**
   * @returns Check if the given token is valid
   * @param token The token to check for validity
   * The token is considered valid if it is present and it does not expire in the next minute
   */
  private isTokenValid(token: Token): boolean {
    const oneMinuteInMs = 60 * 1000;

    const decodedToken = decodeJwtPayload(token);
    return (
      !!decodedToken.exp &&
      decodedToken.exp * 1000 - Date.now() >= oneMinuteInMs
    );
  }

  /**
   * @returns A valid token to make requests to the Project API
   *
   * Will request a new token if there is none yet or the current one is not valid anymore
   */
  public getToken(): Promise<Token> {
    const cachedToken = this.#token;

    // Return the cached token if we have one and it is valid
    if (cachedToken && this.isTokenValid(cachedToken)) {
      return Promise.resolve(cachedToken);
    }

    // If we already have a request running to fetch a new token, return that
    if (this.#tokenRequest) {
      return Promise.resolve(this.#tokenRequest);
    }

    // Otherwise, create a new request
    this.#tokenRequest = this.requestNewToken(this.#tokenRequestPayload)
      // Reset the cached request on completion
      // It will have set `this.#token` that we can reuse next time
      .finally(() => {
        this.#tokenRequest = undefined;
      });

    return this.#tokenRequest;
  }

  /**
   * Requesting a new token for the current user with the provided payload
   *
   * @param tokenPayload The payload to use for requesting the JWT
   * @returns the JWT
   */
  private async requestNewToken(
    tokenPayload: IJWTokenRequestPayload,
  ): Promise<Token> {
    const tokenUrl = new URL("v3/auth/token", this.coreApiBaseUrl);

    const headers = new Headers({
      "Content-Type": "application/json",
      ...clientIdHeader(this.clientId),
    });

    let credentials: RequestCredentials = "include";

    // Add the session token to the headers if it exists and avoid including the already set credentials in that case
    if (this.#sessionToken) {
      headers.append("Cookie", `JSESSIONID=${this.#sessionToken}`);
      credentials = "omit";
    }

    const resp = await fetch(tokenUrl.toString(), {
      method: "post",
      headers,
      body: JSON.stringify(tokenPayload),

      // Making sure to include any available session cookies
      // E.g. from the Dashboard while being embedded in it,
      // but only if the session token is not set
      credentials,
    });

    if (!resp.ok) {
      throw Error(
        `TokenManager: requestNewToken failed with "${resp.statusText}" (${resp.status})`,
      );
    }

    const respData: IGetToken = (await resp.json()).data;

    const { token } = respData;
    this.#token = token;
    return token;
  }
}
